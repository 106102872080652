import { makeObservable, observable } from 'mobx'
import { Model, Store } from './Model'
import { Backend, http } from '../utils'

export class Bmr extends Model {

  @observable id
  @observable year
  @observable week
  @observable name
  @observable srart_date
  @observable end_date
  @observable first_day
  @observable last_day
  @observable bmr_change
  @observable max
  @observable min
  @observable avg
  @observable percent
  @observable fluctuation

  constructor(attributes={}) {
    super(Backend.weekly_bmr_path, Backend.weekly_bmr_path)
    
    Object.assign(makeObservable(this), attributes)
  }

}

export class BmrList extends Store {
  
  constructor() {
    super(Bmr, Backend.weekly_bmr_path)
  }

  async load(params=this.params) {
    if (!(params.start_date && params.end_date)) return;

    Object.assign(this.params, params)
    
    const data = await http().post(this.endpoint(), {
      page: this.current_page,
      page_size: this.page_size,
      sort: this.sort,
      ...this.params
    })

    return this.modify({
      ...data,
      records: data.records.map((record: Model) => new this.model(record))
    }).emit('load', this.records)
  }

}