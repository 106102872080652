import { makeObservable, observable } from 'mobx'
import _ from 'lodash'

import { Model, Store } from './Model'
import { Backend } from '../utils'

export class Notification extends Model {

  @observable id
  @observable name
  @observable status
  @observable level
  @observable content
  @observable subject_id
  @observable subject_type
  @observable created_at
  @observable updated_at
  @observable read_at

  constructor(attributes={}) {
    makeObservable(super(Backend.notifications_path, Backend.notification_path))
    Object.assign(this, attributes)
  }

}

export class NotificationList extends Store {

  constructor() {
    super(Notification, Backend.notifications_path)
  }

  find_by_id(id: string) {
    return this.find_by('id', id)
  }

  find_by(field: string, value: string) {
    return _.find(this.records, [field, value])
  }

}