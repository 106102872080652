import { makeObservable, observable } from 'mobx'
import { Model, Store } from './Model'
import { Backend } from '../utils'

export class Firmware extends Model {

  @observable id = ''
  @observable compiled_at = new Date
  @observable description
  @observable major
  @observable minor
  @observable patch
  @observable published_at = new Date
  @observable reboot = true
  @observable category_id
  @observable channel_id
  @observable channel_name
  @observable channel_title
  @observable version
  file = null

  constructor(attributes={}) {
    makeObservable(super(Backend.firmwares_path, Backend.firmware_path))
    Object.assign(this, attributes)
  }

  valid() {
    if (!this.id && !this.file) {
      alert('请选择需要上传的文件。')
      return false
    }

    return true
  }

  toJS(...args) {
    let hash = super.toJS(...args)

    if (!hash.file) {
      delete hash['file']
    }

    return hash
  }

  submit(verb, url) {
    return super.submit(verb, url, { 
        params: this.toFormData(), 
        options: { upload: true } 
      } 
    )
  }

}

export class FirmwareList extends Store {

  constructor() {
    super(Firmware, Backend.firmwares_path)
  }

}