import { makeObservable, observable } from 'mobx'
import { Model, Store } from './Model'
import { Backend } from '../utils'

export class Alert extends Model {

  @observable id
  @observable name
  @observable description

  @observable start_date
  @observable end_date

  @observable event = 'fault'
  @observable duration

  @observable level = 'log'
  @observable start_at
  @observable end_at

  @observable holiday = true

  @observable monday = true
  @observable tuesday = true
  @observable wednesday = true
  @observable thursday = true
  @observable friday = true
  @observable saturday = true
  @observable sunday = true

  @observable organization_id

  @observable person_ids=[]
  @observable user_ids=[]

  constructor(attributes={}) {
    super(Backend.alerts_path, Backend.alert_path)

    this |> makeObservable |> Object.assign(?, attributes)
  }

  valid() {
    return true
  }

}

export class AlertList extends Store {

  constructor() {
    super(Alert, Backend.alerts_path)
  }

}