import Turbolinks from "turbolinks"
import React, { useState } from 'react'
import { render } from 'react-dom'
import { Row, Col } from 'antd'

import Login from '../components/session/Login'

import logo from '../images/logo-with-label.png'

Turbolinks.start()

document.addEventListener('turbolinks:load', () => {
  render(
    <>
      <Row justify="center" align="middle" className="login-wrap">
        <Col xs={22} sm={16} md={12} lg={6} className="login-box">
          <img src={logo} />
          <Login />
        </Col>
      </Row>
      <footer>
        <p>Copyright  2013 - 2024 Lightalk Tech. Co. Ltd., All Rights Reserved</p>
      </footer>
    </>,
    document.getElementById('root')
  )
})
