import { makeObservable, observable, action } from 'mobx'
import { Model, Store } from './Model'
import { Backend } from '../utils'

const { board_sensors_path, board_sensor_path } = Backend

export class BoardSensor extends Model {

  @observable id
  @observable k
  @observable sn
  @observable can_id
  @observable board_id

  constructor(attributes={}) {

    makeObservable(super(() => board_sensors_path(this.board_id), (id) => board_sensor_path(this.board_id, id)))

    Object.assign(this, attributes)
  }

}

export class BoardSensorList extends Store {
  private board_id

  constructor() {
    super(BoardSensor, () => board_sensors_path({ board_id: this.board_id }))
  }

  @action reset() {
    return this.modify(this, new BoardSensorList().modify({ board_id: this.board_id }))
  }

}