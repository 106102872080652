import { makeObservable, observable } from 'mobx'
import { Model, Store } from './Model'
import { Backend } from '../utils'

const { pages_path, page_path } = Backend

export class Page extends Model {

  @observable id
  @observable name
  @observable idx

  constructor(attributes={}) {
    super(pages_path, page_path)

    makeObservable(this)

    this.modify(attributes)
  }

}

export class PageList extends Store {
  sort = 'idx'
  page_size = 1000

  constructor() {
    super(Page, Backend.pages_path)
  }
}