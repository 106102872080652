import { makeObservable, observable } from 'mobx'
import { Model, Store } from './Model'
import { Backend } from '../utils'

export class Category extends Model {

  @observable id = ''
  @observable key = ''
  @observable name = ''
  @observable description = ''

  constructor(attributes={}) {
    makeObservable(super(Backend.categories_path, Backend.category_path))
    Object.assign(this, attributes)
  }

}

export class CategoryList extends Store {

  constructor() {
    super(Category, Backend.categories_path)
  }

}