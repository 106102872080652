import { makeObservable, observable } from 'mobx'
import { Model, Store } from './Model'
import { Backend } from '../utils'

const { onlines_board_path } = Backend

export class BoardOnline extends Model {

  @observable id
  @observable board_id
  @observable start_at
  @observable end_at
  @observable period

  constructor(attributes={}) {
    super(() => {}, () => {})

    makeObservable(this)

    this.modify(attributes)
  }

  reset() {
    return this.modify(new BoardOnline({board_id: this.board_id}))
  }

}

export class BoardOnlineList extends Store {

  id: string = ''
  
  constructor() {
    super(BoardOnline, () => onlines_board_path(this.id))
  }

}