import { makeObservable, observable } from 'mobx'
import Application from './Application'
import { http, Backend } from '../utils'

const { setting_path } = Backend

class Setting extends Application {

  @observable emqx_endpoint
  @observable emqx_app_id
  @observable emqx_app_secret

  constructor(attributes={}) {
    makeObservable(super())
    this.modify(attributes)
  }

  async load() {
    const data = await http().get(setting_path())
    
    this.modify(data)
  }

  save() {
    return http().put(setting_path(), this.toJS())
  }

}

export const setting = new Setting