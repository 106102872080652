import { makeObservable, observable } from 'mobx'
import { Model, Store } from './Model'
import { Backend } from '../utils'

const { bed_events_path, bed_event_path } = Backend

export class Event extends Model {

  @observable id
  @observable at
  @observable name
  @observable data
  @observable bed_name
  @observable bed_id

  constructor(attributes={}) {
    super(() => bed_events_path(this.bed_id), (id: string) => bed_event_path(this.bed_id, id))

    makeObservable(this)

    this.modify(attributes)
  }

  reset() {
    return this.modify(new Event({bed_id: this.bed_id}))
  }

}

export class EventList extends Store {

  bed_id: string = ''
  
  constructor() {
    super(Event, () => bed_events_path(this.bed_id))
  }

}