import { makeObservable, observable } from 'mobx'
import { Model, Store } from './Model'
import { Backend } from '../utils'

export class Alarm extends Model {

  @observable id
  @observable name
  @observable description

  @observable start_date
  @observable end_date

  @observable event
  @observable negated = false
  @observable start_time
  @observable end_time

  @observable level = 'log'
  @observable start_at
  @observable end_at

  @observable holiday = true

  @observable monday = true
  @observable tuesday = true
  @observable wednesday = true
  @observable thursday = true
  @observable friday = true
  @observable saturday = true
  @observable sunday = true

  @observable organization_id

  @observable person_ids=[]
  @observable user_ids=[]

  constructor(attributes={}) {
    super(Backend.alarms_path, Backend.alarm_path)

    this |> makeObservable |> Object.assign(?, attributes)
  }

  valid() {
    if (!this.start_time || !this.end_time) {
      alert('时间跨度不能为空')
      return false
    }

    return true;
  }

}

export class AlarmList extends Store {

  constructor() {
    super(Alarm, Backend.alarms_path)
  }

}