import { makeObservable, observable } from 'mobx'
import { Model, Store } from './Model'
import { http, Backend } from '../utils'

const { role_users_path, role_user_path } = Backend

export class RoleUser extends Model {

  @observable id
  @observable role_id
  @observable role_name
  @observable user_id
  @observable user_name
  @observable organization_name

  constructor(attributes={}) {

    makeObservable(super(() => role_users_path(this.role_id), (user_id) => role_user_path(this.role_id, user_id)))

    Object.assign(this, attributes)
  }

}

export class RoleUserList extends Store {

  constructor(private role_id) {
    super(RoleUser, () => role_users_path(this.role_id))
  }

  save(ids=[]) {
    return http().put(Backend.role_users_path(this.role_id), { ids })
  }

}