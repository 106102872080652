import { makeObservable, observable } from 'mobx'
import { Model, Store } from './Model'
import { Backend, http } from '../utils'

export class WeightFluctuate extends Model {

  @observable id
  @observable name
  @observable year
  @observable week_of_year
  @observable srart_date
  @observable end_date
  @observable min_percent
  @observable max_percent
  @observable avg_percent
  @observable median_percent
  @observable min_fluctuation
  @observable max_fluctuation
  @observable avg_fluctuation
  @observable median_fluctuation

  constructor(attributes={}) {
    super()
    
    Object.assign(makeObservable(this), attributes)
  }

}

export class WeightFluctuateList extends Store {
  
  constructor(url = Backend.weekly_weight_fluctuate_path) {
    super(WeightFluctuate, url)
  }

  async load(params=this.params) {
    if (!(params.start_date && params.end_date)) return;

    Object.assign(this.params, params)
    
    const data = await http().post(this.endpoint(), {
      page: this.current_page,
      page_size: this.page_size,
      sort: this.sort,
      ...this.params
    })

    return this.modify({
      ...data,
      records: data.records.map((record: Model) => new this.model(record))
    }).emit('load', this.records)
  }

}