import { makeObservable, observable } from 'mobx'
import { Model, Store } from './Model'
import { Backend } from '../utils'

const { alarm_subscriptions_path, subscription_path } = Backend

export class Subscription extends Model {

  @observable id
  @observable start_time
  @observable end_time
  @observable level
  @observable alarm_id

  constructor(attributes={}) {
    super(() => alarm_subscriptions_path(this.alarm_id), id => subscription_path(id))

    makeObservable(this)

    this.modify(attributes)
  }

}

export class SubscriptionList extends Store {

  alarm_id: string = ''
  
  constructor() {
    super(Subscription, () => alarm_subscriptions_path(this.alarm_id))
  }

}