import { makeObservable, observable } from 'mobx'
import { Model, Store } from './Model'
import { Backend, http } from '../utils'

export class WeightChange extends Model {

  @observable id
  @observable date
  @observable name
  @observable change

  constructor(attributes={}) {
    super(Backend.daily_weight_change_path, Backend.daily_weight_change_path)
    
    Object.assign(makeObservable(this), attributes)
  }

}

export class WeightChangeList extends Store {
  
  constructor() {
    super(WeightChange, Backend.daily_weight_change_path)
  }

  async load(params=this.params) {
    if (!(params.start_date && params.end_date)) return;
    
    Object.assign(this.params, params)
    
    const data = await http().post(this.endpoint(), {
      page: this.current_page,
      page_size: this.page_size,
      sort: this.sort,
      ...this.params
    })

    return this.modify({
      ...data,
      records: data.records.map((record: Model) => new this.model(record))
    }).emit('load', this.records)
  }

}