import { makeObservable, observable } from 'mobx'
import { Model, Store } from './Model'
import { Backend } from '../utils'

const { bed_usages_path, bed_usage_path } = Backend

export class Usage extends Model {

  @observable id
  @observable bed_id
  @observable bed_name
  @observable person_id
  @observable person_name
  @observable begin
  @observable end
  @observable code

  constructor(attributes={}) {
    super(() => bed_usages_path(this.bed_id), (id: string) => bed_usage_path(this.bed_id, id))

    makeObservable(this)

    this.modify(attributes)
  }

  reset() {
    return this.modify(new Usage({bed_id: this.bed_id}))
  }

}

export class UsageList extends Store {

  bed_id: string = ''
  
  constructor() {
    super(Usage, () => bed_usages_path(this.bed_id))
  }

}