import { makeObservable, observable } from 'mobx'
import { Model, Store } from './Model'
import { Backend } from '../utils'

const { board_reconnects_path, board_reconnect_path } = Backend

export class Reconnect extends Model {

  @observable id
  @observable at
  @observable network
  @observable type

  constructor(attributes={}) {
    super(() => board_reconnects_path(this.board_id), (id: string) => board_reconnect_path(this.board_id, id))

    makeObservable(this)

    this.modify(attributes)
  }

  reset() {
    return this.modify(new Reconnect({board_id: this.board_id}))
  }

}

export class ReconnectList extends Store {

  board_id: string = ''
  
  constructor() {
    super(Reconnect, () => board_reconnects_path(this.board_id))
  }

}