import { makeObservable, observable, computed } from 'mobx'
import { Model, Store } from './Model'
import { Backend } from '../utils'
import _ from 'lodash'
import { Bunk } from './Bunk'

const { page_cards_path, card_path } = Backend

export class Card extends Model {

  @observable id
  @observable title
  @observable x = 0
  @observable y
  @observable w = 2000
  @observable h = 140
  @observable page_id
  @observable bed_ids = []

  bunks = []

  constructor(attributes={}) {
    super(() => page_cards_path({page_id: this.page_id}), card_path)

    makeObservable(this)

    return this.modify({
      ...attributes,
      bunks: (attributes.bunks || []).map(attr => new Bunk(attr))
    })
  }

  toJS() {
    return super.toJS({only: ['id', 'title', 'x', 'y', 'w', 'h', 'page_id']})
  }

}

export class CardList extends Store {
  page_id
  page_size = 1000

  constructor() {
    super(Card, () => page_cards_path({page_id: this.page_id}))
  }

  async destroy(id) {
    await (new Card({ id })).destroy()
    return this.load()
  }

  async save(attributes, reload=true) {
    const card = this.find_by_id(attributes.id)?.modify(attributes) 
                 || new Card({...attributes, page_id: this.page_id})

    await card.save()

    return reload ? this.load() : this
  }

  find_by_id(id): Card | undefined {
    return _.find(this.records, ['id', id])
  }

  find_bunks_by_uuid(uuid: string): Bunk[] {
    return _.filter(this.bunks, ['board_uuid', uuid])
  }

  find_bunks_by_bed_id(bed_id: string): Bunk[] {
    return _.filter(this.bunks, ['bed_id', bed_id])
  }

  @computed get bunk_count(): number {
    return this.beds.length
  }

  @computed get awake_count(): number {
    return _.filter(this.beds, ['status', 'awake']).length
  }

  @computed get sleep_count(): number {
    return _.filter(this.beds, ['status', 'sleep']).length
  }

  @computed get out_of_bed_count(): number {
    return _.filter(this.beds, ['status', 'out_of_bed']).length
  }

  private get bunks(): Bunk[] {
    return this.records.reduce(
      (result, current) => result.concat(current.bunks || [])
    , [])
  }

  private get beds(): Bunk[] {
    return _.uniqBy(this.bunks, 'bed_id')
  }
}