import { makeObservable, observable } from 'mobx'
import { Model, Store } from './Model'
import { Backend } from '../utils'

export class Organization extends Model {

  @observable id = ''
  @observable name = ''
  @observable description = ''
  @observable day_start_point
  @observable title = ''
  @observable copyright = ''
  
  logo = null
  logo_url = ''

  root = false

  constructor(attributes={}) {
    makeObservable(
      super(Backend.organizations_path, Backend.organization_path)
    )
    
    Object.assign(this, attributes)
  }

  toJS(...args) {
    let hash = super.toJS(...args)

    if (!hash.logo) {
      delete hash['logo']
    }

    return hash
  }

  toFormData({ name, only, except } = {}) {
    let data = new FormData()

    const hash = this.toJS({ only, except })

    _.each(hash, (value, key) => {
      if (value) {
        const field = name ? `${name}[${key}]` : key
        data.append(field, value)
      }
    })

    return data
  }

  private submit(verb, url, { params, options } = {}) {
    if (this.logo) {
      return super.submit(verb, url, { 
        params: this.toFormData({only: ['logo', 'title', 'copyright']}), 
        options: { upload: true } 
      })
    } else {
      return super.submit(...arguments)
    }
  }

}

export class OrganizationList extends Store {

  constructor() {
    super(Organization, Backend.organizations_path)
  }

}