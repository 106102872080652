import { makeObservable, observable } from 'mobx'
import _ from 'lodash'
import { Model, Store } from './Model'
import { Backend } from '../utils'

const { addresses_path, address_path } = Backend

export class Address extends Model {

  @observable id
  @observable name
  @observable description
  @observable parent_id
  @observable parent_name
  @observable organization_id
  @observable organization_name
  @observable path

  constructor(attributes={}) {

    makeObservable(super(addresses_path, address_path))

    Object.assign(this, attributes)
  }

  modify(attributes={}) {
    // 处理清空父节点，JSON.stringify 剔除 undefined 值的问题
    if (attributes.hasOwnProperty('parent_id') && attributes.parent_id === undefined) {
      attributes.parent_id = null
    }

    return super.modify(attributes)
  }

  toJS({ only, except } = {}) {
    const hash = super.toJS({ only, except })

    return _.pick(hash, ['id', 'name', 'description', 'organization_id', 'parent_id'])
  }

}

export class AddressList extends Store {
  private show_bed = false

  constructor(attributes={}) {
    super(Address, addresses_path)

    this.modify(attributes)
  }

  load(params) {
    return super.load({...params, show_bed: this.show_bed})
  }

  reset() {
    return this.modify(new AddressList({show_bed: this.show_bed}))
  }
}