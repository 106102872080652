import { makeObservable, observable } from 'mobx'
import { Model, Store } from './Model'
import { Backend } from '../utils'

const { bed_onlines_path, bed_online_path } = Backend

export class Online extends Model {

  @observable id
  @observable bed_id
  @observable board_id
  @observable start_at
  @observable end_at
  @observable period

  constructor(attributes={}) {
    super(() => bed_onlines_path(this.bed_id), (id: string) => bed_online_path(this.bed_id, id))

    makeObservable(this)

    this.modify(attributes)
  }

  reset() {
    return this.modify(new Online({bed_id: this.bed_id}))
  }

}

export class OnlineList extends Store {

  bed_id: string = ''
  
  constructor() {
    super(Online, () => bed_onlines_path(this.bed_id))
  }

}