import { toJS } from 'mobx'
import { action } from 'mobx'
import _ from 'lodash'

export default abstract class Application {

  @action modify(attributes={}) {
    return Object.assign(this, attributes)
  }

  toJS({ only, except } = {}) {
    const hash = toJS(this)

    return only ? _.pick(hash, only) : except ? _.omit(hash, except) : hash
  }

  toForm({ name, only, except } = {}): FormData {
    let data = new FormData()

    this.toJS({ only, except })
      |>
      _.each(?, (value, key) => {
        (name ? `${name}[${key}]` : key) |> data.append(?, value)
      })

    return data
  }

}
