import { makeObservable, observable } from 'mobx'
import { Model, Store } from './Model'
import { Backend } from '../utils'

const { daily_summaries_path, daily_summary_path } = Backend

export class DailySummary extends Model {

  @observable id
  @observable date
  @observable height
  @observable weight
  @observable increase
  @observable total
  @observable deep
  @observable light
  @observable critical
  @observable awake
  @observable bmi
  @observable bmr
  @observable weighed_at
  @observable main_sleep_total
  @observable minor_sleep_total
  @observable quality

  @observable main_sleep_id
  @observable person_id



  constructor(attributes={}) {
    super(daily_summaries_path, daily_summary_path)

    makeObservable(this)

    this.modify(attributes)
  }

}

export class DailySummaryList extends Store {
  
  sort = '-date'

  constructor(attributes: Object = {}) {
    super(DailySummary, daily_summaries_path)

    this.modify(attributes)
  }

}