import axios from 'axios'
import {Modal, message as Alert} from 'antd'

import { login_path } from './services.js'

export const http = (config = {}) => {
  const { headers, upload, version = 1 } = config

  let hash = {
    "Accept": `application/json;version=${version}`,
    "Content-Type": "application/json",
    ...headers
  };

  // 上传文件
  if (upload) delete hash["Content-Type"]

  const instance = axios.create({
    headers: hash
  })

  instance.interceptors.response.use(
    ({ data }) => data,
    error => {
      handleError(error)
      return Promise.reject(error)
    }
  );
  
  return instance
}

  // 统一异常处理
const handleError = ({ message, response }) => {
  if (response) {
    const { status, data } = response

    if (status === 401) { // 未登录
      Modal.error({
        title: "提示",
        content: '会话超时，请重新登录系统！',
        keyboard: false,
        onOk: () => {
          window.location.replace = login_path()
        }
      })
    } else {
      Alert.error(errorDetail(data));
    }
  } else {
    Alert.error(message);
  }
}

const errorDetail = ({ message, detail, error }: { message: string, detail: object, error: object }) => {
  if (!message) return error

  if (detail) return `${message}（${detail}）`

  return message
}