import { makeObservable, observable } from 'mobx'
import { Model, Store } from './Model'
import { Backend, http } from '../utils'

const { 
  sleep_reports_path, 
  sleep_report_path, 
  candidate_sleep_report_path, 
  assign_sleep_report_path,
  valid_sleep_reports_path,
  invalid_sleep_reports_path 
} = Backend

export class SleepReport extends Model {

  @observable id
  @observable in_at              //（第一次）上床时间
  @observable out_at             //（最后一次）下床时间
  @observable slept_at           // 入睡时间（为空时，表示没有深睡）
  @observable awoke_at           // 苏醒时间
  @observable total              // 睡眠总时长，单位秒
  @observable deep               // 深睡时长
  @observable light              // 浅睡时长
  @observable awake              // 苏醒时长
  @observable critical           // 临界时长
  @observable quality            // 睡眠质量评分
  @observable weight             // 体重参考值，单位克
  @observable weighed_at         // 获取体重参考值的时间
  @observable metabolic          // 睡眠代谢量
  @observable bmr                // 睡眠代谢率
  @observable bmr_correction     // 全时长睡眠代谢率校正
  @observable created_at         
  @observable status             // 状态（未校对 0，有效 1，无效 -1）

  @observable bed_id
  @observable bed_name
  @observable bed_full_name

  @observable person_id
  @observable person_name

  @observable original

  @observable people = []

  constructor(attributes={}) {
    super(sleep_reports_path, sleep_report_path)

    makeObservable(this)

    this.modify(attributes)
  }

  async candidate() {
    const response = await http().get(candidate_sleep_report_path(this.id))

    return this.modify(response)
  }

  assign({ person_id }) {
    return http().put(assign_sleep_report_path(this.id), { person_id })
  }

}

export class SleepReportList extends Store {

  constructor(attributes={}) {
    super(SleepReport, sleep_reports_path)

    this.modify(attributes)
  }

  valid(ids) {
    return http().put(valid_sleep_reports_path(), { ids })
  }

  invalid(ids) {
    return http().put(invalid_sleep_reports_path(), { ids })
  }

}