import { makeObservable, observable } from 'mobx'
import { Model, Store } from './Model'
import { Backend } from '../utils'

const { board_stages_path, board_stage_path } = Backend

export class BoardStage extends Model {

  @observable id
  @observable device_id
  @observable created_at
  @observable name

  constructor(attributes={}) {
    super(() => board_stages_path(this.device_id), (id: string) => board_stage_path(this.device_id, id))

    makeObservable(this)

    this.modify(attributes)
  }

  reset() {
    return this.modify(new BoardStage({device_id: this.device_id}))
  }

}

export class BoardStageList extends Store {

  device_id: string = ''
  
  constructor() {
    super(BoardStage, () => board_stages_path(this.device_id))
  }

}