import { makeObservable, observable, computed } from 'mobx'
import moment from 'moment'

import { Model, Store } from './Model'
import { DailySummaryList } from './DailySummary'
import { http, Backend } from '../utils'

export class Person extends Model {

  @observable id
  @observable name
  @observable sex = 'male'
  @observable phone
  @observable birthday
  @observable height
  @observable weight
  @observable recent_weight
  @observable expect_weight
  @observable recent_weighed_at
  @observable id_card
  @observable organization_id

  @observable day_start_point

  @observable low_vitals = []

  @observable sleep_details = []

  last_out_bed_at

  update_expect_weight = false

  private _summaries = new DailySummaryList({ sort: 'date', statistics: true })

  constructor(attributes={}) {
    super(Backend.people_path, Backend.person_path)

    makeObservable(this)

    this.modify(attributes)
  }

  @computed get day_start_at() {
    return this.day_start.format('HH:mm')
  }

  @computed get day_start() {
    return moment.utc(moment.duration(this.day_start_point, "s").as('milliseconds'))
  }

  load_summaries(params={}) {
    if (this.id) return this._summaries.load({ ...params, person_id: this.id, page_size: 10000000 })
  }

  get summaries() {
    return this._summaries.records
  }

  get weight_averages() {
    return this._summaries.records.map(
      item => {
        return [
          {
            date: item.date,
            type: '体重',
            value: item.weight / 1000
          },
          {
            date: item.date,
            type: '三日均线',
            value: item.three_day_weight / 1000
          },
          {
            date: item.date,
            type: '七日均线',
            value: item.seven_day_weight / 1000
          },
          {
            date: item.date,
            type: '十五日均线',
            value: item.fifteen_day_weight / 1000
          },
          {
            date: item.date,
            type: '三十日均线',
            value: item.thirty_day_weight / 1000
          },
        ]
      }
    ).flat()
  }

  get bmr_averages() {
    return this._summaries.records.map(
      item => {
        return [
          {
            date: item.date,
            type: 'BMR',
            value: item.bmr
          },
          {
            date: item.date,
            type: '三日均线',
            value: item.three_day_bmr
          },
          {
            date: item.date,
            type: '七日均线',
            value: item.seven_day_bmr
          },
          {
            date: item.date,
            type: '十五日均线',
            value: item.fifteen_day_bmr
          },
          {
            date: item.date,
            type: '三十日均线',
            value: item.thirty_day_bmr
          },
        ]
      }
    ).flat()
  }

  async load_low_vitals(params={}) {
    if (this.id) {
      const items = await http().get(Backend.person_low_vitals_path(this.id, params))

      this.modify({ low_vitals: items })
    }
  }

  async load_sleep_details(params={}) {
    if (this.id) {
      const items = await http().get(Backend.person_sleep_details_path(this.id, params))

      this.modify({ sleep_details: items })
    }
  }

}

export class PersonList extends Store {
  
  constructor(attributes: Object = {}) {
    super(Person, Backend.people_path)

    this.modify(attributes)
  }

}

export class People extends Store {
  
  constructor() {
    super(Person, Backend.candidate_people_path)
  }

}