import { makeObservable, observable } from 'mobx'
import { Model, Store } from './Model'
import { http, Backend } from '../utils'
import _ from 'lodash'

const { boards_path, board_path } = Backend

export class Board extends Model {

  @observable id
  @observable name
  @observable description
  @observable sn
  @observable uuid
  @observable mac
  @observable iccid
  @observable imei
  @observable stage = 'initial'
  @observable category_id
  @observable channel_id
  @observable protocol = 'V3'
  @observable algorithm
  @observable firmware
  @observable status // 离线 disconnected; 在线 connected
  
  highlight = false
  sensors = []
  bunks = []

  constructor(attributes={}) {
    super(boards_path, board_path)

    makeObservable(this)

    this.modify(attributes)
  }

  toJS({ only, except } = {}) {
    const hash = super.toJS({ only, except })

    return _.omit(hash, ['sensors', 'bunks', 'beds'])
  }

  async refresh_state() {
    if (this.id) {
      const { connected } = await http().get(Backend.state_board_path(this.id))

      this.modify({
        status: connected ? 'connected' : 'disconnected'
      })
    } 
  }

  kick() {
    http().delete(Backend.kick_board_path(this.id))
  }

}

export class BoardList extends Store {
  constructor() {
    super(Board, Backend.boards_path)
  }

  find_by_uuid(uuid: string) {
    return this.find_by('uuid', uuid)
  }

  find_by_id(id: string) {
    return this.find_by('id', id)
  }

  find_by(field: string, value: string) {
    return _.find(this.records, [field, value])
  }

}