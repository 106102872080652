import { makeObservable, observable } from 'mobx'
import { Model, Store } from './Model'
import { http, Backend } from '../utils'

export class ShortCode extends Model {

  @observable id
  @observable active = false
  @observable category = 'board'
  @observable prefix
  @observable min
  @observable max
  @observable created_at


  constructor(attributes={}) {
    super(Backend.short_codes_path, Backend.short_code_path)

    makeObservable(this)

    this.modify(attributes)
  }

  activate() {
    return this.id |> this.url |> http().patch(?, { active: true })
  }

}

export class ShortCodeList extends Store {

  constructor() {
    super(ShortCode, Backend.short_codes_path)
  }

}