import { makeObservable, observable } from 'mobx'
import { Model, Store } from './Model'
import { Backend } from '../utils'

export class Channel extends Model {

  @observable id = ''
  @observable name = ''
  @observable title = ''
  @observable description = ''

  constructor(attributes={}) {
    makeObservable(super(Backend.channels_path, Backend.channel_path))
    Object.assign(this, attributes)
  }

}

export class ChannelList extends Store {

  constructor() {
    super(Channel, Backend.channels_path)
  }

}