import { makeObservable, observable, computed } from 'mobx'
import moment from 'moment'
import _ from "lodash"

import { Model, Store } from './Model'
import { http, Backend } from '../utils'

class Profile extends Model {

  @observable id
  @observable name
  @observable username
  @observable nickname
  @observable phone
  @observable email
  @observable organization_id
  @observable organization_name
  @observable day_start_point
  @observable token
  @observable menus = []
  @observable authorities = []
  @observable groups = []

  constructor() {
    super(Backend.profile_path, Backend.profile_path)
    makeObservable(this)
  }

  @computed get day_start_at() {
    return this.day_start.format('HH:mm')
  }

  @computed get day_start() {
    return moment.utc(moment.duration(this.day_start_point, "s").as('milliseconds'))
  }

  can(features) {
    return _.intersection(this.authorities, _.castArray(features)).length > 0
  }

}

export const profile = new Profile