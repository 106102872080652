import { makeObservable, observable } from 'mobx'
import { Model, Store } from './Model'
import { Backend, http } from '../utils'

export class Group extends Model {

  @observable id
  @observable name
  @observable description
  @observable people_count
  @observable beds_count
  @observable creator_id
  @observable creator_name
  @observable organization_id
  @observable organization_name
  @observable person_ids=[]
  @observable bed_ids=[]

  constructor(attributes={}) {
    super(Backend.groups_path, Backend.group_path)
    
    Object.assign(makeObservable(this), attributes)
  }

}

export class GroupList extends Store {

  constructor() {
    super(Group, Backend.groups_path)
  }

}