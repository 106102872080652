import { makeObservable, observable, action } from 'mobx'
import Application from './Application'

const COUNT = 60; // 60 秒倒计时

export class Countdown extends Application {
  @observable current: number

  constructor(private value: number) {
    makeObservable(super())
    this.start = value || COUNT
  }

  @action countdown(value: number = this.start) {
    if (value > 0) setTimeout(() => this.countdown(value - 1), 1000)

    this.current = value <= 0 ? 0 : value
  }
}