import { makeObservable, observable } from 'mobx'
import { Model, Store } from './Model'
import { Backend } from '../utils'

const { sensor_stages_path, sensor_stage_path } = Backend

export class SensorStage extends Model {

  @observable id
  @observable device_id
  @observable created_at
  @observable name

  constructor(attributes={}) {
    super(() => sensor_stages_path(this.device_id), (id: string) => sensor_stage_path(this.device_id, id))

    makeObservable(this)

    this.modify(attributes)
  }

  reset() {
    return this.modify(new SensorStage({device_id: this.device_id}))
  }

}

export class SensorStageList extends Store {

  device_id: string = ''
  
  constructor() {
    super(SensorStage, () => sensor_stages_path(this.device_id))
  }

}