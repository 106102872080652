import { makeObservable, observable } from 'mobx'
import { Model, Store } from './Model'
import { Backend } from '../utils'

const { ageing_results_path, ageing_result_path } = Backend

export class AgeingResult extends Model {

  @observable id
  @observable at
  @observable report
  @observable passed
  @observable board_id

  constructor(attributes={}) {
    super(ageing_results_path, ageing_result_path)

    makeObservable(this)

    this.modify(attributes)
  }

}