import { makeObservable, observable, action, computed } from 'mobx'
import _ from 'lodash'
import { Model, Store } from './Model'
import { Backend } from '../utils'

const { beds_path, bed_path } = Backend

export class Bed extends Model {

  @observable id
  @observable name
  @observable description
  @observable type = "single"
  @observable address_id
  @observable organization_id
  @observable organization_name
  @observable board
  @observable sensors = []
  @observable status = 'disconnected' // 离线 disconnected; 在线 connected; 睡着 sleep; 苏醒 awake; 不在床 out_of_bed
  @observable board_id
  @observable board_sn
  @observable board_uuid
  address_path
  people = []

  constructor(attributes={}) {
    super(beds_path, bed_path)

    makeObservable(this)

    Object.assign(this, attributes)
  }

  @computed get full_name() {
    return `${this.address_path} / ${this.name}`
  }

}

export class BedList extends Store {
  constructor() {
    super(Bed, Backend.beds_path)
  }

  find_by_uuid(uuid) {
    return _.find(this.records, ['board_uuid', uuid])
  }

  find_by_id(id) {
    return _.find(this.records, ['id', id])
  }
}

export class BunkList extends Store {

  url: string = ''

  constructor(attributes={}) {
    super(Bed, () => this.url)
    this.modify(attributes)
  }

}