import { makeObservable, observable } from 'mobx'
import _ from 'lodash'

import { Model, Store } from './Model'
import { Backend } from '../utils'

const { sensors_path, sensor_path } = Backend

export class Sensor extends Model {

  @observable id
  @observable k
  @observable sn
  @observable value
  @observable stage = 'initial'
  @observable can_id
  @observable board_id
  @observable created_at
  @observable updated_at

  highlight = false

  constructor(attributes={}) {
    super(sensors_path, sensor_path)

    makeObservable(this)

    this.modify(attributes)
  }

}

export class SensorList extends Store {

  constructor() {
    super(Sensor, Backend.sensors_path)
  }

  find_by_id(id: string) {
    return this.find_by('id', id)
  }

  find_by(field: string, value: string) {
    return _.find(this.records, [field, value])
  }

}