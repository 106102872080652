import { makeObservable, observable } from 'mobx'
import { Model, Store } from './Model'
import { Backend } from '../utils'

export class User extends Model {

  @observable id
  @observable name
  @observable username
  @observable phone
  @observable email
  @observable organization_id
  @observable organization_name

  password = ''
  password_confirmation = ''

  constructor(attributes={}) {
    makeObservable(super(Backend.users_path, Backend.user_path))
    Object.assign(this, attributes)
  }

}

export class UserList extends Store {

  constructor() {
    super(User, Backend.users_path)
  }

}