import { makeObservable, observable, computed } from 'mobx'
import { Model } from './Model'
import { Backend } from '../utils'

const { card_bunks_path, bunk_path, selected_card_bunks_path } = Backend

export class Bunk extends Model {

  @observable id
  @observable idx
  @observable card_id
  @observable bed_id
  @observable bed_name
  @observable person_id
  @observable person_name
  @observable board_uuid
  @observable persons = []
  // 离线 disconnected; 在线 connected; 睡着 sleep; 苏醒 awake; 不在床 out_of_bed
  @observable status = 'disconnected' 

  constructor(attributes={}) {
    super(() => card_bunks_path({card_id: this.card_id}), bunk_path)

    makeObservable(this)

    this.modify(attributes)
  }

}